<template>
  <div class="card p-0 mt-5">
    <div class="table-responsive">
      <table class="table table-items mt-n2">
        <thead>
          <tr class="fs-6 fw-bold text-dark-gray-2">
            <th scope="col">MEMBERSHIP LEVEL</th>
            <th scope="col">CITY & STATE</th>
            <th scope="col">DATE</th>
            <th scope="col">NEW VIP MEMBER NAME</th>
            <th scope="col">REFERRED BY</th>
            <th scope="col" class="col-1">RECEIVED POINTS</th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="6" class="w-100 p-5 text-center">
              <div class="spinner-border text-gray" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="list.length === 0">
          <tr>
            <td colspan="6" class="w-100 p-5 text-center text-bold">
              Data not available
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <template v-for="row in list" :key="row.in">
            <tr v-if="row?.user" class="fs-6">
              <td>{{ row?.user?.subscription_type }}</td>
              <td>
                <template
                  v-if="
                    row?.user?.is_merchant &&
                    row?.user?.business_profile?.location
                  "
                >
                  {{
                    [
                      row?.user?.business_profile?.location?.city,
                      row?.user?.business_profile?.location?.state,
                    ].join(", ")
                  }}
                </template>
                <template v-else-if="row?.user?.profile">
                  {{
                    [row?.user?.profile?.city, row?.user?.profile?.state].join(
                      ", "
                    )
                  }}
                </template>
              </td>
              <td>{{ moment(row?.created_at).format("MMM DD, YYYY") }}</td>
              <td>
                <span class="link-name" data="modal" @click="profile(row)">
                  <ce-image
                    class="rounded mr-3 tbl-img"
                    :src="row?.user?.primary_photo?.sizes?.thumbnail"
                    :placeholder="`/images/defaults/gender-other.png`"
                    :alt="`Referrals List - ${row?.user?.first_name} ${row?.user?.last_name}`"
                  />
                  <span v-if="row?.user?.first_name" class="text-blue">
                    {{
                      [row?.user?.first_name, row?.user?.last_name].join(" ")
                    }}
                  </span>
                  <span v-else class="text-blue">{{ row?.user?.email }}</span>
                </span>
              </td>
              <td>
                <span class="link-name" data="modal" @click="profile(row)">
                  <ce-image
                    class="rounded mr-3 tbl-img"
                    :src="row?.referred_by?.primary_photo?.sizes?.thumbnail"
                    :placeholder="`/images/defaults/gender-other.png`"
                    :alt="`Referrals List - ${row?.referred_by?.first_name} ${row?.referred_by?.last_name}`"
                  />
                  <span v-if="row?.referred_by?.first_name" class="text-blue">
                    {{
                      [
                        row?.referred_by?.first_name,
                        row?.referred_by?.last_name,
                      ].join(" ")
                    }}
                  </span>
                  <span v-else class="text-blue">{{
                    row?.referred_by?.email
                  }}</span>
                </span>
              </td>
              <td>
                <template v-if="row?.points > 1">
                  {{ `${row?.points} points` }}
                </template>
                <template v-else>
                  {{ `${row?.points} point` }}
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div
      v-if="hasPagination"
      class="d-flex justify-content-center align-content-center p-3"
    >
      <ce-pagination
        v-model:current-page="currentPage"
        :per-page="perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import { ref, watch, inject } from "vue";
import CePagination from "@/components/Paginations/CePagination.vue";

export default {
  name: "ReferralsTable",
  components: {
    CePagination,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasPagination: {
      type: Boolean,
      required: false,
      default: false,
    },
    list: {
      type: Array,
      required: false,
      default: () => [],
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
    perPage: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ["done"],
  setup(props, { emit }) {
    const moment = inject("moment");

    const totalPage = ref(3);
    const currentPage = ref(1);

    const profile = (row) => {
      emit("showProfile", row?.user?.user_name);
    };

    watch(
      () => currentPage.value,
      (newPage) => emit("changePage", newPage)
    );

    return {
      moment,
      totalPage,
      currentPage,
      profile,
    };
  },
};
</script>

<style scoped>
.color-gray {
  color: #727e8b;
}

.form-control:focus {
  box-shadow: none;
}

.btn:focus {
  box-shadow: none;
}

.color-gold {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}

.top-70 {
  top: 0% !important;
}

.start-58 {
  left: 58% !important;
}

.card {
  border: 0;
}

.widget-user-header {
  padding: 0;
  height: auto !important;
}

.widget-user .widget-user-header {
  padding: 0px;
}

.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}

.dropdown-menu-lg {
  min-width: auto;
}

.link-name {
  color: rgb(85, 85, 194);
}

.link-name:hover {
  cursor: pointer;
}

.row-name {
  width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.view-profile {
  color: #b18d47;
}

.cancel-profile {
  color: #878787;
}

.link-profile:hover {
  cursor: pointer;
}

.table-responsive {
  margin-top: -35px;
}

.tbl-img {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}

.profile-img {
  object-fit: contain;
  border-radius: 0.25em 0.25em 0em 0px;
  width: 100%;
}

.table-items th {
  padding-bottom: 2px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.table-items tbody,
tr,
td {
  white-space: nowrap !important;
}

.fa-circle-plus,
.fa-pen-to-square,
.fa-magnifying-glass-plus:hover {
  cursor: pointer;
}
</style>
