<template>
    <div>
        <div class="card card-success mb-4">
            <div class="card-body row d-flex align-items-center">
                <div class="col flex-fill">
                    <div class="row align-items-center">
                        <div class="col fs-2 ff-merriweather text-dark-gray fw-bold">Create New Custom Code</div>
                    </div>
                </div>
            </div>
        </div>

        <custom-referral-form></custom-referral-form>

        <div id="confirm" />
    </div>
</template>

<script>
import CustomReferralForm from "@/components/Referrals/CustomReferralForm.vue";

export default {
    name: "App",
    components: {
        CustomReferralForm,
    },
    props: {
        //
    },
    emits: ["isLoading"],
    setup(props, { emit }) {
        return {
            //
        };
    },
    created: function () {
        // this.moment = moment;
    },
};
</script>

<style scoped>
.downloadAndMessageAlign {
    text-align: center;
    position: inherit;
    margin-top: 15px;
}

.dropdown-menu-lg {
    min-width: auto;
}

.table td,
.table th {
    border-top: 0 !important;
}

.table td {
    vertical-align: middle;
}

.custom-control-label {
    font-weight: 500 !important;
}

.send {
    color: rgb(4, 98, 193);
}

.selected-message-type {
    background-color: #333;
    color: #cacaca !important;
}
</style>
