<template>
    <div>
        <div class="row mb-5">
            <div class="mb-3">
                <span class="fs-5 fw-bold"> Name: </span> {{ name }}
            </div>
            <div class="mb-3">
                <span class="d-block fw-bold mb-2">Start Date</span>
                <ce-text v-model="from" type="calendar" :min-date="minStartDate">
                    <template #prepend>
                        <span
                            class="input-group-text icon-calendar-alt ps-3 border-0 bg-light-gray"
                        ></span>
                    </template>
                </ce-text>
            </div>
            <div>
                <span class="d-block fw-bold mb-2">End Date</span>
                <ce-text v-model="to" type="calendar" :min-date="minStartDate">
                    <template #prepend>
                        <span
                            class="input-group-text icon-calendar-alt ps-3 border-0 bg-light-gray"
                        ></span>
                    </template>
                </ce-text>
            </div>
        </div>
        <div class="text-center mb-2 d-flex justify-content-end">
            <div class="me-2">
                <ce-button
                type="button"
                class="btn-md btn-light-gray flex-fill"
                data-bs-dismiss="modal"
                >
                    Cancel
                </ce-button>
            </div>
            <div>
                <ce-button
                type="button"
                class="btn-md btn-gradient-gold flex-fill"
                data-bs-dismiss="modal"
                @click="confirmAsFeatured"
                >
                    Apply
                </ce-button>
            </div>
        </div>
    </div>
</template>
<script>

import { ref, inject, computed } from "vue";

export default {
    props: {
        modelType: {
            type: String,
            default: 'deals',
        },
        modelData:{
            type: Object,
            default: () => {}
        }
    },
    emits: ['confirmAsFeatured'],
    setup(props, { emit }) {
        const moment = inject('moment');
        const dialog = inject('dialog')
        const from = ref(null);
        const to = ref(null);
        const confirmAsFeatured = () => {
            if (from.value && to.value){
                emit('confirmAsFeatured', from.value, to.value)
            }else{
                dialog.show('Start date and End date is required', {
                    title: 'Error',
                });
            }
        }

        const name = computed(() => {
            return props.modelType === 'deals' ? props.modelData.name : props.modelData.business_name;
        });

        const minStartDate = computed(() => {
            let timezone = '';
            if (props.modelType === 'deals'){
                timezone = props.modelData.business_profile?.timezone?.name;
            }
            if (props.modelType === 'merchants'){
                timezone = props.modelData.timezone?.name;
            }
            return timezone
                ? moment
                    .tz(timezone)
                    .format('YYYY-MM-DD')
                : undefined
        });

        return { name, from, to, minStartDate, confirmAsFeatured }
    }
}
</script>
