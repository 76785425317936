<template>
  <div class="row g-5">
    <section
      v-for="(row, key) in widgets"
      :key="key"
      class="col-md-4 col-sm-6 col-12 connectedSortable"
    >
      <div class="card h-100 w-100">
        <div class="card-title info-box mt-3 px-3">
          <span class="info-box-icon bg-info">
            <i :class="`fa fa-${row.icon}`" />
          </span>
          <div class="info-box-content" @click="goTo(row.link)">
            <h3 class="info-box-text">
              {{ row.labels }}
            </h3>
            <strong class="info-box-number color-gray">{{ row.data }}</strong>
          </div>
          <div class="card-tools">
            <a :href="row.link" class="badge badge-warning text-decoration-none"
              >Manage</a
            >
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="row mt-2">
            <div class="col-12">
              <hr class="mt-0" />
              <div class="row mt-2">
                <div
                  v-for="(header, index) in row.heading"
                  :key="index"
                  class="col-sm-4 col-6"
                >
                  <router-link
                    :to="`${row.link}?filter=${index.toLowerCase()}`"
                    class="description-block text-decoration-none text-center"
                  >
                    <h5 class="description-header">
                      {{ capitalize(index) }}
                    </h5>
                    <span class="description-text align-middle color-gray">{{
                      row.heading[index]
                    }}</span>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-12 mt-3">
              <div class="row">
                <div class="col-6 border-end text-left">
                  <p v-for="(leftMenu, i) in row.menu1" :key="i">
                    <a
                      :href="leftMenu.link"
                      class="text-info text-decoration-none"
                      >{{ leftMenu.label }}</a
                    >
                  </p>
                </div>
                <div class="col-6 text-left">
                  <p v-for="(rightMenu, i) in row.menu2" :key="i">
                    <a
                      :href="rightMenu.link"
                      class="text-info text-decoration-none"
                      >{{ rightMenu.label }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- /.content -->
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import { useTextModification } from "@/Use/global.js";

export default {
  name: "App",
  setup() {
    const store = useStore();

    const { capitalize } = useTextModification();

    const userStatistics = computed(() => store.state.user.userStatistics[0]);
    const groupStatistics = computed(
      () => store.state.group.groupStatistics[0]
    );
    const eventStatistics = computed(
      () => store.state.event.eventStatistics[0]
    );
    const huddleStatistics = computed(
      () => store.state.event.huddleStatistics[0]
    );

    // Deals menu
    const dealsStatistics = computed(
      () => store.state.deal.dealStatistics[0]
    );

    // Merchants menu
    const merchantsStatistics = computed(
      () => store.state.merchant.merchantStatistics[0]
    );

    const widgets = [].concat(
      userStatistics.value,
      groupStatistics.value,
      eventStatistics.value,
      huddleStatistics.value,
      dealsStatistics.value,
      merchantsStatistics.value
    );

    const goTo = (data) => {
      router.push(data);
    };

    onMounted(async() => {
      await store.dispatch("loadUserStatistics");
      await store.dispatch("group/loadGroupStatistic");
      await store.dispatch("loadEventStatistic", { gatheringType: 1 });
      await store.dispatch("loadEventStatistic", { gatheringType: 2 });
      await store.dispatch("getEnums");

      // deal card menu
      await store.dispatch("loadUDealStatistics");

      // merchant card menu
      await store.dispatch("loadMerchantStatistics");
    })

    return {
      widgets,
      userStatistics,
      groupStatistics,
      eventStatistics,
      huddleStatistics,
      goTo,
      capitalize,
    };
  },
};
</script>

<style>
.color-gray {
  color: #999;
}
.description-block {
  text-align: left;
}
.info-box-content:hover {
  cursor: pointer;
}

.card-title.info-box {
  box-shadow: none;
}
</style>
