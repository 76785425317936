import { inject } from 'vue';

export const useRequestCatchDialog = () => {
    const dialog = inject('dialog');
    const catchDialog = (exception, status, customTitle, customMessage) => {
        let dialogTitle = 'System Error';
        let dialogMessage =
            'An unexpected error encountered. Please try again later.';

        if (exception.response?.status === status) {
            dialogTitle = customTitle ?? exception.response?.statusText;

            dialogMessage = customMessage ?? exception.response?.data.error;
        }

        return dialog.show(dialogMessage, {
            title: dialogTitle,
        });
    };

    return {
        catchDialog,
    };
};

export default null;
