import { createWebHistory, createRouter } from 'vue-router'
import Login from '@/Pages/Auth/AuthenticateLayout.vue'
import AdminLayout from '@/Layouts/Default.vue'
import Home from '@/Pages/Home.vue'
import Inbox from '@/Pages/Inbox.vue'
import Alert from '@/Pages/Alert.vue'
import Reporting from '@/Pages/Reporting.vue'

import store from '../store'

import userRoutes from './user.js'
import groupRoutes from './group.js'
import eventRoutes from './event.js'
import huddleRoutes from './huddles.js'
import dealRoutes from './deal.js'
import merchantRoutes from './merchant.js'
import referralRoutes from './referrals.js'

const routes = [
    {
        path: "/:catchAll(.*)",
        meta: {
            name: '',
            requiresAuth: true
        },
        redirect: {
            path: '/login'
        },
    }, {
        path: '/',
        meta: {
            name: '',
            requiresAuth: false
        },
        component: Login,
        beforeEnter: (to, from, next) => {
            if (store.getters.authorized) {
                next('/login')
            } else {
                next()
            }
        }
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            name: 'Login Page',
            requiresAuth: false
        },
        component: Login
    },
    {
        path: '/*',
        component: AdminLayout,
        meta: {
            name: 'Admin Panel',
            requiresAuth: true
        },
        children: [
            {
                path: '/home',
                name: 'home',
                component: Home,
                meta: { requiresAuth: true },
            },
            {
                path: '/inbox',
                name: 'inbox',
                component: Inbox,
                meta: { requiresAuth: true },
            },
            {
                path: '/alert',
                name: 'alert',
                component: Alert,
                meta: { requiresAuth: true },
            },
            {
                path: '/reporting',
                name: 'reporting',
                component: Reporting,
                meta: { requiresAuth: true },
            },
            ...userRoutes,
            ...groupRoutes,
            ...eventRoutes,
            ...huddleRoutes,
            ...dealRoutes,
            ...merchantRoutes,
            ...referralRoutes
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.authorized) {
            next('/login')
        } else {
            if (from.fullPath !== to.fullPath) {
                localStorage.setItem("prevURL", from.fullPath);
            }
            next()
        }
    } else {
        if (from.fullPath !== to.fullPath) {
            localStorage.setItem("prevURL", from.fullPath);
        }
        next()
    }
})

export default router
