<template>
  <!-- <div class="card card-success">
    <div class="card-body">
      <header-stories-component
        :module-name="`user`"
        :search-for="`users`"
        :query-type="queryType"
        :total="$store.state.user.userStatistics[0].data"
        @done="filterAdvance($event)"
        @search-filter="searchHeader($event)"
        @reset="reset"
      />
    </div>
  </div> -->
  <div class="card card-success">
    <div class="card-body color-gray">
      <div class="row">
        <div class="col-md-12 col-lg-8 col-xl-9">
          <div class="row">
            <statistics-card-component
              :card="statics"
              :type="`users`"
              @change-status="changeStatus($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <deals-table/>
  <div id="confirm" />
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import moment from "moment";
import DealsTable from "@/components/Tables/DealsTable.vue";
import StatisticsCardComponent from "@/components/StatisticsCardComponent.vue";
import HeaderStoriesComponent from "@/components/HeaderStoriesComponent.vue";

export default {
  name: "App",
  components: {
    DealsTable,
    StatisticsCardComponent,
    HeaderStoriesComponent,
  },
  setup() {

    const status = ref("active");
    const store = useStore();
    const filterAddress = ref(null);
    const headerFilter = ref(null);

    const route = useRoute();
    const queryType = route.query.type;
    const queryFilter = route.query.filter;

    const statics = ref([
      {
        label: "All",
        value: store.state.deal.dealStatistics[0].heading.All,
      },
      {
        label: "Active",
        value: store.state.deal.dealStatistics[0].heading.Active,
      },
      {
        label: "Drafts",
        value: store.state.deal.dealStatistics[0].heading.Drafts,
      },
      {
        label: "Scheduled",
        value: store.state.deal.dealStatistics[0].heading.Scheduled,
      },
      {
        label: "Expired",
        value: store.state.deal.dealStatistics[0].heading.Expired,
      },
      {
        label: "Deactivated",
        value: store.state.deal.dealStatistics[0].heading.Deactivated,
      },
      {
        label: "Featured",
        value: store.state.deal.dealStatistics[0].heading.Featured,
      }
    ]);

    const dataTableUser = ref("");

    const changeStatus = (event) => {
      dataTableUser.value = event;
    };

    const searchHeader = (event) => {
      dataTableUser.value = "headerFilter";
      headerFilter.value = event;
    };

    const filterAdvance = (data) => {
      filterAddress.value = data;
    };

    const reset = () => {
      dataTableUser.value = "all";
    };

    return {
      changeStatus,
      status,
      statics,
      dataTableUser,
      filterAddress,
      filterAdvance,
      searchHeader,
      headerFilter,
      reset,
      queryType,
      queryFilter,
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>

<style scoped>
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.table td,
.table th {
  border-top: 0 !important;
}
.table td {
  vertical-align: middle;
}
.custom-control-label {
  font-weight: 500 !important;
}
.send {
  color: rgb(4, 98, 193);
}
.selected-message-type {
  background-color: #333;
  color: #cacaca !important;
}
</style>
