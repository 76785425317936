<template>
  <Preloader :is-loading="isLoading"></Preloader>
  <div class="card p-0 mt-5">
    <div class="table-responsive">
      <table class="table table-items">
        <thead>
          <tr>
            <th scope="col">
              <div class="d-flex">
                <ce-checkbox v-model="all" :value="true" @click.prevent="selectedAll()"/>
                <h1 class="fs-14 fw-bold color-gray">
                  <span class="ml-3">NAME</span>
                </h1>
              </div>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-uppercase">Description</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-uppercase">Deal owner</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-uppercase">Redemptions</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-uppercase">Featured</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-uppercase">Show on search</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-uppercase">Status</h1>
            </th>
            <!-- <th scope="col">
              <h1 class="fs-14 fw-bold color-gray text-center text-uppercase">Take action</h1>
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in dealLists" :key="row.index" class="fs-14">
            <td>
              <ce-checkbox v-model="selectedDeals" :value="row.id">
                 <img
                    class="rounded mr-3 tbl-img"
                    :src="row.photos[0]?.sizes?.thumbnail"
                  />
                {{ row.name }}
              </ce-checkbox>

            </td>
            <td>
                <div class="dealName">
                {{ row.description }}
                </div>
            </td>
            <td>
                  <img
                    class="rounded mr-3 tbl-img"
                    :src="row.business_profile.user.primary_photo?.sizes?.thumbnail"
                  />
                {{ row.business_profile.business_name }}
            </td>
            <td>
                {{ row.redeemed_count }}
            </td>
            <td>
              <ce-button
               v-if="row.is_featured_has_been_set"
                type="button"
                class="btn-md btn-gradient-gold flex-fill"
                data-bs-dismiss="modal"
                @click="viewFeatured(row)"
              >
                <span :class="row.is_still_featured  ? 'text-green' : 'text-dark-gray-2'"> {{ row.is_still_featured ? 'Active' : 'Expired' }} </span>
              </ce-button>
              <ce-button
                v-else
                type="button"
                class="btn-md btn-gradient-gold flex-fill"
                data-bs-dismiss="modal"
                @click="setAsFeatured(row)"
              >
                Set as featured
              </ce-button>
            </td>
            <td>
              <ce-checkbox v-model="row.show_on_search_result" :value="row.id" @change="showOnSearch(row)"/>
            </td>
            <td>
                <span class="p-2" :class="statusIdentity(row.status).class">
                    {{ statusIdentity(row.status).status }}
                </span>
            </td>
            <!-- <td>
                &nbsp;
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <pagination-component
      class="mt-3 mb-2 mr-5"
      :total-pages="totalPage"
      :per-page="25"
      :current-page="currentPage"
      @pagechanged="onPageChange"
    />
    <teleport to="body">
      <ce-modal ref="modalRef" class="p-0">
        <div class="modal-header d-flex">
            <strong v-if="modalType === 'set-featured'" class="fs-4">Set As Featured</strong>
            <strong v-if="modalType === 'view-featured'" class="fs-4">View Featured</strong>
        </div>
        <div class="modal-body">
          <set-featured
            v-if="modalType === 'set-featured'"
            :modelData="selectedRow"
            @confirmAsFeatured="confirmAsFeatured"
          />
          <view-featured
            v-if="modalType === 'view-featured'"
            :modelData="selectedRow"
            @updateAsFeatured="confirmAsFeatured"
          />
        </div>
      </ce-modal>
    </teleport>
  </div>
</template>
<script>
import { ref, computed, inject, onMounted, watch  } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import ModalComponent from "@/components/ModalComponent.vue";
import UserInformationComponent from "../../Pages/User/UserInformationModal.vue";
import MessageModal from "../../components/Modal/MessageModal.vue";
import NotesModal from "../Modal/NotesModal.vue";
import Preloader from "../../Layouts/Common/Preloader.vue";
import PaginationComponent from "../PaginationComponent.vue";
import SetFeatured from '@/components/Modal/SetFeatured.vue';
import ViewFeatured from '@/components/Modal/ViewFeatured.vue';

export default {
  name: "App",
  components: {
    ModalComponent,
    UserInformationComponent,
    MessageModal,
    NotesModal,
    Preloader,
    PaginationComponent,
    SetFeatured,
    ViewFeatured
  },
  setup() {
    const moment = inject('moment');
    const dialog = inject('dialog')
    const store = useStore();
    const dealLists = ref([]);
    const isLoading = ref(false);
    const currentPage = ref(1);
    const totalPage = ref(null);
    const modalRef = ref(null);
    const modalType = ref(null);
    const selectedDeals = ref([]);
    const selectedRow = ref(null);
    const all = ref(null);
    const auth = computed(() => store.state.user.authUser)

    const getDeals = async(page = 1) => {
        isLoading.value = true;
        let response = [];
        let params = {
            page: page,
            perPage: 25
        };

        const status = router.currentRoute._value.query?.filter;

        if (status === 'all' || !status) {
            params = {...params, ...{filter_by_status : 0}};
        }

        if (status === 'active' || !status) {
            params = {...params, ...{is_active : 1}};
        }

        if (status === 'drafts') {
            params = {...params, ...{is_draft : 1}};
        }

        if (status === 'scheduled') {
            params = {...params, ...{is_scheduled : 1}};
        }

        if (status === 'expired') {
            params = {...params, ...{is_expired : 1}};
        }

        if (status === 'deactivated') {
            params = {...params, ...{is_deactivated : 1}};
        }

        if (status === 'featured') {
            params = {...params, ...{is_featured_been_set : 1, filter_by_status: 0}};
        }

        response = await store.dispatch("loadUDealList", params);

        dealLists.value = response.data;
        totalPage.value = response.meta.last_page;
        isLoading.value = false;
    }

    const statusIdentity = (status) => {
        if (status === 1) {
            return { status: 'Active', class: 'bg-green' };
        }
        if (status === 0) {
            return { status: 'Drafts', class: 'bg-dark-green' };
        }

        if (status === 2) {
            return { status: 'Scheduled', class: 'bg-orange' };
        }

        if (status === 3) {
            return { status: 'Deactivated', class: 'bg-red' };
        }

        if (status === 4) {
            return { status: 'Expired', class: 'bg-dark-gray-2' };
        }
        return null;
    };

    const onPageChange = (page) => {
      currentPage.value = page;
      getDeals(page);
    };

    const selectedAll = () => {
      if (!all.value){
        all.value = true;
        selectedDeals.value = dealLists.value.map((deal) => deal.id);
      }else{
        selectedDeals.value = [];
        all.value = false;
      }
    }

    const setAsFeatured = (row) => {
      selectedRow.value = row;
      modalType.value = 'set-featured';
      modalRef.value.open();
    }

    const viewFeatured = (row) => {
      selectedRow.value = row;
      modalType.value = 'view-featured';
      modalRef.value.open();
    }

    const confirmAsFeatured = async(from, to) => {
      const { data } = await store.dispatch("setAsFeatured", {id: selectedRow.value.id, featured_start_at: from, featured_end_at: to});
      const index = dealLists.value.findIndex((deal) => deal.id === selectedRow.value.id);
      if (index){
        dealLists.value[index] = data;
        dialog.show('Set as featured success', {
            title: 'Success',
        });
      }
    }

    const showOnSearch = async(deal) =>   {
      await store.dispatch("setDealShowOnResult", {id: deal.id, show_on_search_result: deal.show_on_search_result});
      dialog.show(`Show to search results is successfully ${deal.show_on_search_result ? 'enabled' : 'disabled'}`, {
          title: 'Success',
      });
    }

    // watchers
    watch(router.currentRoute, () => {
      selectedDeals.value = [];
      all.value = false;
      onPageChange(1)
    })

    onMounted(async() => {
      await getDeals(1);
    })

    return {
      auth,
      moment,
      isLoading,
      statusIdentity,
      currentPage,
      totalPage,
      dealLists,
      onPageChange,
      selectedDeals,
      modalRef,
      modalType,
      selectedAll,
      setAsFeatured,
      viewFeatured,
      confirmAsFeatured,
      selectedRow,
      all,
      showOnSearch
    };
  },
};
</script>
<style scoped>
.color-gray {
  color: #727e8b;
}
.form-control:focus {
  box-shadow: none;
}
.btn:focus {
  box-shadow: none;
}
.color-gold {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}
.top-70 {
  top: 0% !important;
}
.start-58 {
  left: 58% !important;
}
.card {
  border: 0;
}
.widget-user-header {
  padding: 0;
  height: auto !important;
}
.widget-user .widget-user-header {
  padding: 0px;
}
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.link-name {
  color: rgb(85, 85, 194);
}
.link-name:hover {
  cursor: pointer;
}
.fa {
  color: black;
}
.fas:hover {
  cursor: pointer;
}
.fs-14 {
  font-size: 14px !important;
}

.table-responsive {
  margin-top: -35px;
}
.tbl-ll-date {
  color: #6c757d;
  position: relative;
  left: 25px;
  float: left;
  margin-top: -5px;
}
.tbl-img {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}
.profile-img {
  object-fit: contain;
  border-radius: 0.25em 0.25em 0em 0px;
  width: 100%;
}
.table-items th {
  padding-bottom: 2px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.table-items tbody,
tr,
td {
  white-space: nowrap !important;
}
.background-gold {
  background: gold;
}
.txt-color-gray {
  color: #6c757d;
}
.fa-circle-plus,
.fa-pen-to-square,
.fa-magnifying-glass-plus:hover {
  cursor: pointer;
}
.dealName{
    width: 200px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
