<template>
  <div>
    <div class="card p-0 mt-5">
      <div class="table-responsive">
        <table class="table table-items">
          <thead>
            <tr>
              <th scope="col">
                <h1 class="fs-6 fw-bold color-gray">
                  <input type="checkbox" id="checkAll" />
                  <span class="ml-3">GROUPS</span>
                </h1>
              </th>
              <th scope="col">
                <h1 class="fs-6 fw-bold color-gray">GROUP OWNER</h1>
              </th>
              <th scope="col">
                <h1 class="fs-6 fw-bold color-gray"># OF MEMBERS</h1>
              </th>
              <th scope="col">
                <h1 class="fs-6 fw-bold color-gray">STATUS</h1>
              </th>
              <th scope="col">
                <h1 class="fs-6 fw-bold color-gray text-center">TAKE ACTION</h1>
              </th>
              <th scope="col">
                <h1 class="fs-6 fw-bold color-gray text-center">NOTES</h1>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in groupList" :key="row.in" class="fs-6">
              <td>
                <div class="icheck-primary d-inline">
                  <input type="checkbox" id="checkboxPrimary1" />
                  <span
                    class="ml-3 link-name"
                    data="modal"
                    @click="showGroup(row)"
                  >
                    <img
                      class="rounded mr-3 tbl-img"
                      :src="row?.image_modified_url?.sizes?.sm"
                      @error="imageUrlAlt"
                    />
                    {{ row?.name }}
                  </span>
                </div>
              </td>
              <td>
                <span
                  class="link-name"
                  data="modal"
                  @click="showProfile(row?.user)"
                >
                  <img
                    class="rounded mr-3 tbl-img"
                    :src="row?.user?.primary_photo?.sizes?.sm"
                    @error="imageUrlAlt"
                  />
                  {{ row?.user?.first_name }} {{ row?.user?.last_name }}
                </span>
              </td>
              <td>{{ row?.total_members }} Members</td>
              <td>
                {{
                  row.status === "Flagged" && row.deleted_at === null
                    ? "Flagged"
                    : row.status === "Suspended" && row.deleted_at === null
                    ? "Suspended"
                    : row.status === "Deactivated" && row.deleted_at === null
                    ? "Deactivated"
                    : row.is_published === true && row.deleted_at === null
                    ? "Active"
                    : row.deleted_at !== null
                    ? "Deleted"
                    : row.is_published === false && row.deleted_at === null
                    ? "Unpublished"
                    : ""
                }}
              </td>
              <td class="text-center" v-if="queryType == `edit`">
                <router-link
                  class="nav-link"
                  :to="{
                    name: 'group-edit',
                    params: { slug: row.slug },
                  }"
                >
                  Edit
                </router-link>
              </td>
              <td class="text-center" v-else-if="queryType == `publish`">
                <a
                  href="#"
                  @click="goToAction('publish', row)"
                  class="nav-link p-0"
                >
                  Publish
                </a>
              </td>
              <td class="text-center" v-else>
                <div v-if="row.deleted_at === null">
                  <a class="nav-link" data-toggle="dropdown" href="#">
                    Take Action
                  </a>
                  <div class="dropdown-menu dropdown-menu-left">
                    <a
                      href="#"
                      @click="goToAction('edit', row)"
                      class="dropdown-item p-2"
                    >
                      Edit
                    </a>

                    <a
                      v-if="row.status === `Flagged` && row.deleted_at === null"
                      href="#"
                      @click="goToAction('remove-flag', row)"
                      class="dropdown-item p-2"
                    >
                      Remove Flag
                    </a>
                    <a
                      v-else
                      href="#"
                      @click="goToAction('flag', row)"
                      class="dropdown-item p-2"
                    >
                      Flag
                    </a>
                    <a
                      v-if="row.status === `Suspended` && row.deleted_at === null"
                      href="#"
                      @click="goToAction('unsuspend', row)"
                      class="dropdown-item p-2"
                    >
                      Unsuspend
                    </a>
                    <a
                      v-else
                      href="#"
                      @click="goToAction('suspend', row)"
                      class="dropdown-item p-2"
                    >
                      Suspend
                    </a>
                    <a
                      v-if="
                        row.status === `Deactivated` && row.deleted_at === null
                      "
                      href="#"
                      @click="goToAction('reactivate', row)"
                      class="dropdown-item p-2"
                    >
                      Reactivate
                    </a>
                    <a
                      v-else
                      href="#"
                      @click="goToAction('deactivate', row)"
                      class="dropdown-item p-2"
                    >
                      Deactivate
                    </a>
                    <a
                      v-if="
                        row.is_published === false && row.deleted_at === null
                      "
                      href="#"
                      @click="goToAction('publish', row)"
                      class="dropdown-item p-2"
                    >
                      Publish
                    </a>
                    <a
                      href="#"
                      @click="goToAction('delete', row)"
                      class="dropdown-item p-2"
                    >
                      Delete
                    </a>
                  </div>
                </div>
                <div v-else class="text-red">Not Available</div>
              </td>
              <td class="text-center">
                <span v-if="row.user_notes.length === 0">
                  <i class="fas fa-circle-plus mr-2" @click="addNotes(row)"></i>
                </span>
                <span v-else>
                  <i
                    class="fas fa-pen-to-square mr-2"
                    @click="editNotes(row)"
                  ></i>
                  <i
                    class="fas fa-magnifying-glass-plus"
                    @click="viewNotes(row)"
                  ></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-component
        class="mt-3 mb-2 mr-5"
        :total-pages="totalPage"
        :per-page="25"
        :current-page="currentPage"
        @pagechanged="onPageChange"
      />
    </div>

    <modal-component :modal-id="`modalId`">
      <template v-slot:modalBody>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12">
              <div class="row">
                <div class="col-md-5 col-lg-5 col-sm-12">
                  <profile-image-component
                    v-if="userAccount"
                    :image-src="`${userAccount?.primary_photo?.modified}`"
                  />
                </div>
                <div class="col-md-7 col-lg-7 col-sm-12">
                  <user-information-component
                    v-if="userAccount"
                    :user="userAccount"
                    @cancel="cancel"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal-component>
    <modal-component :modal-id="`group-preview`" :size="`modal-md`">
      <template v-slot:modalBody>
        <div class="modal-body p-0">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12">
              <div class="w-100 h-100 position-relative">
                <div class="ce-utilities-aspect-ratio">
                  <div
                    class="ce-utilities-aspect-ratio-wrapper restricted-aspect-ratio"
                  >
                    <img
                      :src="group?.image_modified_url?.modified"
                      alt="Perfect Friends"
                      class="mw-100 h-100 profile-img"
                    />
                  </div>
                </div>
                <div class="col p-3">
                  <div class="mt-2">
                    <h3>{{ group?.name }}</h3>
                  </div>
                  <div class="mt-4">
                    <strong>Moderator</strong>
                  </div>
                  <div class="mt-1">
                    <span
                      >{{ group?.user?.first_name }}
                      {{ group?.user?.last_name }}</span
                    >
                  </div>
                  <div class="mt-4">
                    <strong>About</strong>
                  </div>
                  <div class="mt-1">
                    <span>{{ group?.description }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 float-right">
              <span
                class="float-left link-profile cancel-profile"
                @click="cancelPreview"
                >Cancel</span
              >
              <span
                class="float-right link-profile view-profile"
                @click="viewGroup(group?.slug)"
                >View Group</span
              >
            </div>
          </div>
        </div>
      </template>
    </modal-component>
    <modal-component
      :modal-id="`notes-modal`"
      :title="notesTitle"
      :header-style="`bg-linear`"
      :size="`modal-md`"
      :profile="false"
    >
      <template v-slot:modalBody>
        <notes-modal
          :notes-account="notesAccount"
          @done="done"
          :is-preview="isPreview"
          :notes="note"
          :is-edit="isEdit"
          :notes-type="`group`"
        />
      </template>
    </modal-component>
  </div>
</template>
<script>
import { nextTick, onMounted, ref, watch } from "vue";
import $ from "jquery";
import { useStore } from "vuex";
import moment from "moment";
import router from "@/router";
import { userEnums, GENERALSTATUS, TAKEACTION } from "@/composable/enums.js";
import ModalComponent from "@/components/ModalComponent.vue";
import ProfileImageComponent from "../../components/ProfileImageComponent.vue";
import UserInformationComponent from "../../Pages/User/UserInformationModal.vue";
import PaginationComponent from "../PaginationComponent.vue";
import NotesModal from "../Modal/NotesModal.vue";
export default {
  name: "App",
  components: {
    ModalComponent,
    ProfileImageComponent,
    UserInformationComponent,
    // MessageModal,
    PaginationComponent,
    NotesModal,
  },
  props: {
    userType: {
      type: String,
    },
    queryType: {
      type: String,
      default: "view",
    },
    queryFilter: {
      type: String,
      default: null,
    },
    filter: {
      type: String,
      default: "",
      required: false,
    },
    headerFilter: {
      type: String,
      default: "",
      required: false,
    },
    queryType: {
      type: String,
      default: "all",
    },
    queryFilter: {
      type: String,
      default: null,
    },
  },
  emits: ["done"],
  setup(props, { emit }) {
    const store = useStore();
    const groupList = ref([]);
    const userAccount = ref(store.state.user.userList[0]);
    const { memberships, takeAction } = userEnums();
    const modalId = ref("view-profile");
    const isShowModal = ref(false);
    const group = ref(null);
    const currentPage = ref(1);
    const totalPage = ref(null);
    const selectedTakeAction = ref([]);
    const notesAccount = ref("");
    const isPreview = ref(false);
    const isEdit = ref(false);
    const note = ref(null);
    const selectOption = ref("");
    const filterType = ref("");
    const notesTitle = ref("Create Note");

    const showModal = () => {
      $(`#modalId`).modal("show");
    };
    const cancel = () => {
      isShowModal.value = false;
      $(`#modalId`).modal("hide");
    };
    const selectMessageToSend = () => {
      $(`#selectMessageType`).modal("show");
    };
    const loadGroup = async (event) => {
      emit("done", true);
      localStorage.removeItem("statisticsType");
      let response = [];
      if (props.filter !== null && event === undefined) {
        response = await store.dispatch("group/loadSearchGroupList", {
          type: "advance_search",
          page_type: props.queryType,
          page_filter: props.queryFilter,
          search: props.filter,
          perPage: currentPage.value,
        });
      } else if (event === "headerFilter" && props.headerFilter !== null) {
        response = await store.dispatch("group/loadSearchGroupList", {
          type: "header_filter",
          page_type: props.queryType,
          page_filter: props.queryFilter,
          search: props.headerFilter,
          perPage: currentPage.value,
        });
      } else {
        response = await store.dispatch("group/loadGroupList", {
          type: event,
          page_type: props.queryType,
          page_filter: props.queryFilter,
          perPage: 25,
          page: currentPage.value,
        });
      }
      groupList.value = response.data;
      if (response) {
        totalPage.value = response.meta.last_page;
        emit("done", false);
      }
    };
    const checkFlag = (row) => {
      let isFlag = row.filter((value) => value.type === "flag");
      return isFlag.length > 0 ? true : false;
    };
    const imageUrlAlt = (event) => {
      event.target.src = "//via.placeholder.com/150";
    };
    const goToAction = async (type, group) => {
      if (type === "edit") {
        router.push({
          name: "group-edit",
          params: { slug: group.slug },
        });
      } else if (type === "published") {
        const response = await store.dispatch("group/publishedGroup", group.id);
        if (response) {
          await store.dispatch("group/loadGroupStatistic");
          router.go();
        }
      } else {
        router.push(`/group/${type}/${group.slug}`);
      }
    };
    const showProfile = async (user) => {
      emit("done", true);
      const data = JSON.parse(JSON.stringify(user));
      const response = await store.dispatch("loadGroupProfile", {
        value: user.user_name,
      });
      localStorage.setItem("profile", response);
      userAccount.value = response;
      modalId.value = "user-" + data.id;
      $(`#modalId`).modal("show");
      emit("done", false);
    };
    const showGroup = (row) => {
      group.value = row;
      $(`#group-preview`).modal("show");
    };
    const cancelPreview = () => {
      $(`#group-preview`).modal("hide");
    };
    const viewGroup = (slug) => {
      window.location.href = "/group/" + slug + "/preview";
    };
    const onPageChange = (page) => {
      currentPage.value = page;
    };
    const editNotes = (account) => {
      nextTick(() => {
        isPreview.value = false;
        isEdit.value = true;
        notesAccount.value = account;
        note.value = account.user_notes;
        notesTitle.value = "Edit Notes";
        $("#notes-modal").modal("show");
      });
    };
    const viewNotes = (account) => {
      nextTick(() => {
        isPreview.value = true;
        isEdit.value = false;
        notesAccount.value = account;
        note.value = account.user_notes;
        notesTitle.value = "View Notes";
        $("#notes-modal").modal("show");
      });
    };
    const done = () => {
      loadGroup("all");
      notesAccount.value = "";
      $("#notes-modal").modal("hide");
    };
    const addNotes = (group) => {
      nextTick(() => {
        note.value = "";
        isPreview.value = false;
        isEdit.value = false;
        notesAccount.value = group;
        $("#notes-modal").modal("show");
      });
    };
    onMounted(() => {
      localStorage.setItem("prevURL", router.currentRoute._value.href);
      selectOption.value = router.currentRoute._value.query.type;
      filterType.value = router.currentRoute._value.query.filter;
      setFilter(selectOption.value ?? filterType.value);

      if (selectOption.value === undefined && filterType.value === undefined) {
        loadGroup("all");
      }
    });

    const setFilter = (data) => {
      
      if (data === "active") {
        loadGroup(TAKEACTION.ACTIVE);
      }
      if (data === "deactivated") {
        loadGroup(GENERALSTATUS.DEACTIVATED);
      }
      if (data === "unpublished") {
        loadGroup(GENERALSTATUS.UNPUBLISHED);
      }
      if (data === "flagged") {
        loadGroup(GENERALSTATUS.FLAGGED);
      }
      if (data === "suspended") {
        loadGroup(GENERALSTATUS.SUSPENDED);
      }
      if (data === "deleted") {
        loadGroup(GENERALSTATUS.DELETED);
      }

      if (data === "edit") {
        selectedTakeAction.value = {
          link: "edit",
          label: "Edit Group",
        };
        selectOption.value = "all";
        loadGroup("all");
      }
      if (data === "publish") {
        selectedTakeAction.value = {
          link: selectOption.value,
          label: "Publish",
        };
        selectOption.value = "unpublished";
        loadGroup(GENERALSTATUS.UNPUBLISHED);
      }
      if (data === "flag") {
        selectedTakeAction.value = { link: "flag", label: "Flag" };
        selectOption.value = "active";
        loadGroup("active");
      }
      if (data === "suspend") {
        selectedTakeAction.value = {
          link: "suspend",
          label: "Suspend",
        };
        selectOption.value = "suspend";
        loadGroup(TAKEACTION.SUSPEND);
      }
      if (data === "deactivate") {
        selectedTakeAction.value = {
          link: "deactivate",
          label: "Deactivate",
        };
        selectOption.value = "deactivate";
        loadGroup(TAKEACTION.DEACTIVATE);
      }
      if (data === "reactivate") {
        selectedTakeAction.value = {
          link: "reactivate",
          label: "Reactivate",
        };
        loadGroup(TAKEACTION.REACTIVATE);
      }

      if (data === "delete") {
        selectedTakeAction.value = {
          link: "delete",
          label: "Delete",
        };
        selectOption.value = "delete";
        loadGroup(TAKEACTION.DELETE);
      }
      if (data === "active") {
        selectedTakeAction.value = {
          link: selectOption.value,
          label: "Active",
        };
        selectOption.value = "active";
        loadGroup(TAKEACTION.ACTIVE);
      }
      if (data === "unsuspend") {
        selectedTakeAction.value = {
          link: selectOption.value,
          label: "Unsuspend",
        };
        selectOption.value = "suspend";
        loadGroup(GENERALSTATUS.SUSPENDED);
      }
      if (data === "remove-flag") {
        selectedTakeAction.value = {
          link: selectOption.value,
          label: "Remove Flag",
        };
        selectOption.value = "flagged";
        loadGroup(GENERALSTATUS.FLAGGED);
      }
    }

    watch(
      () => props.userType,
      (newValue) => setFilter(newValue)
    );
    watch(
      () => currentPage.value,
      () =>
      {
        selectOption.value = router.currentRoute._value.query.type;
        filterType.value = router.currentRoute._value.query.filter;
        setFilter(
            filterType.value !== undefined
            ? filterType.value
            : selectOption.value !== undefined
            ? selectedTakeAction.value.link
            : "all"
            )
      }
    );
    watch(
      () => props.filter,
      () => loadGroup(undefined)
    );
    watch(
      () => props.headerFilter,
      (newValue) => loadGroup(newValue !== null ? "headerFilter" : "all")
    );
    return {
      groupList,
      checkFlag,
      goToAction,
      memberships,
      takeAction,
      showProfile,
      userAccount,
      showModal,
      cancel,
      selectMessageToSend,
      imageUrlAlt,
      showGroup,
      cancelPreview,
      group,
      viewGroup,
      currentPage,
      onPageChange,
      totalPage,
      selectedTakeAction,
      addNotes,
      notesAccount,
      isPreview,
      isEdit,
      note,
      editNotes,
      viewNotes,
      done,
      selectOption,
      filterType,
      notesTitle,
      setFilter
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style scoped>
.color-gray {
  color: #727e8b;
}
.form-control:focus {
  box-shadow: none;
}
.btn:focus {
  box-shadow: none;
}
.color-gold {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}
.top-70 {
  top: 0% !important;
}
.start-58 {
  left: 58% !important;
}
.card {
  border: 0;
}
.widget-user-header {
  padding: 0;
  height: auto !important;
}
.widget-user .widget-user-header {
  padding: 0px;
}
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.link-name {
  color: rgb(85, 85, 194);
}
.link-name:hover {
  cursor: pointer;
}
.row-name {
  width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.view-profile {
  color: #b18d47;
}
.cancel-profile {
  color: #878787;
}
.link-profile:hover {
  cursor: pointer;
}

.table-items th {
  padding-bottom: 2px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.table-items tbody,
tr,
td {
  white-space: nowrap !important;
}
.table-responsive {
  margin-top: -35px;
}
.tbl-img {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}
.profile-img {
  object-fit: contain;
  border-radius: 0.25em 0.25em 0em 0px;
  width: 100%;
}
.fa-circle-plus,
.fa-pen-to-square,
.fa-magnifying-glass-plus:hover {
  cursor: pointer;
}
</style>
