<template>
    <div>
        <div class="row mb-5">
            <div class="mb-3">
                <span class="fs-5 fw-bold"> Name: </span> {{ modelData.name }}
            </div>
            <div class="mb-3">
                <span class="d-block fw-bold mb-2">Start Date</span>
                <ce-text v-model="from" type="calendar">
                    <template #prepend>
                        <span
                            class="input-group-text icon-calendar-alt ps-3 border-0 bg-light-gray"
                        ></span>
                    </template>
                </ce-text>
            </div>
            <div>
                <span class="d-block fw-bold mb-2">End Date</span>
                <ce-text v-model="to" type="calendar">
                    <template #prepend>
                        <span
                            class="input-group-text icon-calendar-alt ps-3 border-0 bg-light-gray"
                        ></span>
                    </template>
                </ce-text>
            </div>
        </div>
        <div class="text-center mb-2 d-flex justify-content-end">
            <div class="me-2">
                <ce-button
                type="button"
                class="btn-md btn-light-gray flex-fill"
                data-bs-dismiss="modal"
                >
                    Cancel
                </ce-button>
            </div>
            <div>
                <ce-button
                type="button"
                class="btn-md btn-gradient-gold flex-fill"
                data-bs-dismiss="modal"
                @click="updateAsFeatured"
                :class="{'disabled' : !isDateChange}"
                >
                    Apply
                </ce-button>
            </div>
        </div>
    </div>
</template>
<script>

import { ref, inject, computed } from "vue";

export default {
    props:{
        modelData:{
            type: Object,
            default: () => {}
        },
    },
    emits: ['updateAsFeatured'],
    setup(props, { emit }){
        const moment = inject('moment');
        const dialog = inject('dialog')
        const from = ref(moment(props.modelData.featured_start_at).format('YYYY-MM-DD'));
        const to = ref(moment(props.modelData.featured_end_at).format('YYYY-MM-DD'));

        const isDateChange = computed(() => {
            return from.value !== moment(props.modelData.featured_start_at).format('YYYY-MM-DD')
            || to.value !== moment(props.modelData.featured_end_at).format('YYYY-MM-DD');
        })

        const updateAsFeatured = () => {
            if (from.value && to.value){
                emit('updateAsFeatured', from.value, to.value)
            }else{
                dialog.show('Start date and End date is required', {
                    title: 'Error',
                });
            }
        }

        return { from, to, isDateChange, updateAsFeatured }
    }
}
</script>
