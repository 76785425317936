<template>
  <div>
    <div class="card card-success mb-0">
      <div class="card-body row d-flex align-items-center">
        <div class="col-8 flex-fill">
          <div class="row align-items-center">
            <div
              class="col-md-4 col-sm-12 fs-2 ff-merriweather text-dark-gray fw-bold"
            >
              Custom Referral Codes
            </div>
            <div
              class="col-md-8 col-sm-12 text-black fs-5 text-md-center text-sm-left"
            >
              {{ customReferralCount }}
            </div>
          </div>
        </div>
        <div class="col-4 flex-fill text-end">
          <router-link
            :to="{ name: 'CreateCustomCode' }"
            class="btn btn-gradient-gold fw-bold align-items-center justify-content-center"
          >
            Create New Custom Referral Code
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="label-filter ff-merriweather text-gray fw-bold text-center fs-5 py-3"
    >
      Filters
    </div>
    <div class="card card-success mb-3">
      <div class="card-body text-dark-gray">
        <div class="row gy-2">
          <div class="col-12 col-md-4 pe-0 pe-md-5">
            <div class="input-group">
              <label class="d-block text-start small fw-semibold ls-1 mb-2"
                >MEMBER NAME</label
              >
              <ce-text
                id="search_by_name"
                v-model="searchByName"
                :placeholder="`Search by names`"
                :input-class="'border-light-gray-border bg-white'"
              />
            </div>
          </div>
          <div class="col-12 col-md-4 pe-0 pe-md-5">
            <div class="input-group">
              <label class="d-block text-start small fw-semibold ls-1 mb-2"
                >LOCATION (City & State)</label
              >
              <ce-text
                id="search_by_location"
                v-model="searchByLocation"
                type="location"
                :placeholder="`Location (City & State)`"
                :input-class="'border-light-gray-border bg-white border-end-0'"
                :input-loading-class="'border-none bg-white border-light-gray-border'"
              />
            </div>
          </div>
          <div class="col-12 col-md-4 pe-0 pe-md-5">
            <div class="input-group">
              <label class="d-block text-start small fw-semibold ls-1 mb-2"
                >DATE RANGE</label
              >
              <template v-if="searchByDate">
                <div class="input-group ce-inputs-text">
                  <input
                    type="text"
                    class="form-control rounded border-light-gray-border bg-white"
                    :value="
                      searchByDate[0] == searchByDate[1]
                        ? moment(searchByDate[0]).format('MMMM DD, YYYY')
                        : [
                            moment(searchByDate[0]).format('MMMM DD, YYYY'),
                            moment(searchByDate[1]).format('MMMM DD, YYYY'),
                          ].join(' - ')
                    "
                    readonly
                  />
                  <ce-button
                    class="input-group-text bg-transparent btn-primary-outline cursor-pointer fw-bold text-gold p-0"
                    @click="clearDateSelection"
                  >
                    <span class="icon-close px-2 border-0 fs-2"></span
                  ></ce-button>
                </div>
              </template>
              <ce-text
                v-else
                :ref="datePickerRef"
                v-model="searchByDate"
                type="date"
                :range="true"
                :placeholder="`Search by date`"
                :input-class="'border-light-gray-border bg-white'"
                allow-input-date
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5 py-1 table-divider"></div>
    <custom-referrals-table
      :is-loading="isLoading"
      :list="list"
      :total="total"
      :has-pagination="true"
      :has-take-action="true"
      :per-page="perPage"
      :edit-data="editData"
      :isEditSubmit="isEditLoading"
      @changePage="getNewPage"
      @showProfile="profile"
      @edit="editCode"
      @cancelEdit="removeIsEditMode"
      @save="saveEditCode"
      @delete="deleteCode"
    />
  </div>

  <modal-component :modal-id="`memberProfile`">
    <template #modalBody>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="row">
              <div class="col-md-5 col-lg-5 col-sm-12">
                <profile-image-component
                  v-if="userAccount"
                  :image-src="`${userAccount?.primary_photo?.modified}`"
                />
              </div>
              <div class="col-md-7 col-lg-7 col-sm-12">
                <user-information-component
                  v-if="userAccount"
                  :user="userAccount"
                  @cancel="cancel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>

  <dialog-modal ref="confirmUpdate" />
</template>

<script>
import { onMounted, ref, defineAsyncComponent, watch, reactive } from "vue";
import debounce from "lodash/debounce";
import { useRequestCatchDialog } from "@/composable/catch-dialog.js";
import { useUserProfileDialog } from "@/composable/catch-dialog.js";
import { useStore } from "vuex";
import moment from "moment";
import CeButton from "@/components/Buttons/CeButton.vue";
import CeText from "@/components/Inputs/CeText.vue";
import CustomReferralsTable from "@/components/Referrals/CustomReferralsTable.vue";

import ModalComponent from "@/components/ModalComponent.vue";
import ProfileImageComponent from "@/components/ProfileImageComponent.vue";
import UserInformationComponent from "../User/UserInformationModal.vue";

import http from "@/http.js";

export default {
  name: "ManagerReferrals",
  components: {
    CustomReferralsTable,
    CeButton,

    ModalComponent,
    ProfileImageComponent,
    UserInformationComponent,
    DialogModal: defineAsyncComponent(() =>
      import("@/components/Modal/DialogModal.vue")
    ),
  },
  props: {
    //
  },
  emits: ["isLoading"],
  setup() {
    const store = useStore();
    const { catchDialog: showCatchDialog } = useRequestCatchDialog();
    const confirmUpdate = ref();

    const editData = reactive({
      id: null,
      previous_code_name: null,
      code_name: null,
    });

    const renderComponent = ref(true);

    const memberProfile = ref("view-profile");
    const isShowModal = ref(false);
    const userAccount = ref(null);

    const customReferralCount = ref(0);
    const isLoadingCount = ref(false);

    const datePickerRef = ref(null);

    const list = ref([]);
    const total = ref(null);
    const isLoading = ref(true);
    const perPage = ref(10);
    const currentPage = ref(1);

    const isEditLoading = ref(false);

    const searchByName = ref(null);
    const searchByLocation = ref({
      city: null,
      state: null,
      stateLongName: null,
      zipCode: null,
      lat: null,
      lng: null,
    });
    const searchByDate = ref(null);

    const loadTableList = async () => {
      isLoading.value = true;
      try {
        const response = await store.dispatch(`getCustomReferralList`, {
          perPage: perPage.value,
          page: currentPage.value,
          name: searchByName.value != null ? searchByName.value : null,
          city:
            searchByLocation.value != null ? searchByLocation.value.city : null,
          state:
            searchByLocation.value != null
              ? searchByLocation.value.state
              : null,

          stateLongName:
            searchByLocation.value != null
              ? searchByLocation.value.stateLongName
              : null,
          date_from: searchByDate.value != null ? searchByDate.value[0] : null,
          date_to:
            searchByDate.value != null
              ? searchByDate.value[0] == searchByDate.value[1]
                ? null
                : searchByDate.value[1]
              : null,
        });

        list.value = response.data;
        perPage.value = parseInt(response.meta.per_page);
        total.value = parseInt(response.meta.total);
      } catch (exception) {
        showCatchDialog(exception, 422);
      } finally {
        isLoading.value = false;
      }
    };

    const loadCustomReferralCodeCount = async () => {
      isLoadingCount.value = true;
      try {
        const response = await store.dispatch(`getCustomReferralCodeCount`);
        customReferralCount.value = response.data?.total;
      } catch (exception) {
        showCatchDialog(exception, 422);
      } finally {
        isLoadingCount.value = false;
      }
    };

    const getNewPage = (newPage) => {
      currentPage.value = newPage;
      loadTableList();
    };

    const clearDateSelection = () => {
      searchByDate.value = null;
    };

    const profile = async (userName) => {
      try {
        const response = await store.dispatch("loadUserProfile", {
          value: userName,
        });
        userAccount.value = response;

        $("#memberProfile").modal("show");
      } catch (exception) {
        showCatchDialog(exception, 422);
      }
    };
    const cancel = () => {
      isShowModal.value = false;
      $("#memberProfile").modal("hide");
    };

    const removeIsEditMode = () => {
      return list.value.map((item) => (item.isEditMode = false));
    };

    const editCode = (row) => {
      removeIsEditMode();
      const findItem = list.value.find((i) => i.id === row?.id);

      editData.id = row?.id;
      editData.previous_code_name = row?.referral_code;
      editData.code_name = row?.referral_code;

      findItem.isEditMode = true;
      const findIndex = list.value.findIndex((i) => i.id === row?.id);
      list.value.splice(findIndex, 1, findItem);
    };

    const saveEditCode = (formData) => {
      isEditLoading.value = true;
      confirmUpdate.value.prompt("", {
        title: "Are you sure you want to update this custom code?",
        buttons: [
          {
            label: "No",
            class: "btn-outline-black",
            handler: () => {
              isEditLoading.value = false;
              confirmUpdate.value.close();
            },
          },
          {
            label: "Yes",
            class: "btn-gradient-gold",
            handler: async () => {
              console.log("formData", formData);
              try {
                const response = await http.patch(
                  `admin-panel/custom-referral-codes/${formData.id}`,
                  {
                    referral_code: formData.referralCode,
                  }
                );

                const findItem = list.value.find((i) => i.id === formData.id);
                findItem.referral_code = response?.data?.data?.referral_code;
                const findIndex = list.value.findIndex(
                  (i) => i.id === formData.id
                );
                list.value.splice(findIndex, 1, findItem);
                removeIsEditMode();
              } catch (exception) {
                showCatchDialog(exception, 422);
                isEditLoading.value = false;
              } finally {
                isEditLoading.value = false;
                confirmUpdate.value.close();
              }
            },
          },
        ],
      });
    };

    const deleteCode = (rowId) => {
      confirmUpdate.value.prompt("", {
        title: "Are you sure you want to delete this custom code?",
        buttons: [
          {
            label: "No",
            class: "btn-outline-black",
            handler: () => {
              isEditLoading.value = false;
              confirmUpdate.value.close();
            },
          },
          {
            label: "Yes",
            class: "btn-gradient-gold",
            handler: async () => {
              try {
                await http.delete(`admin-panel/custom-referral-codes/${rowId}`);

                const findIndex = list.value.findIndex((i) => i.id === rowId);
                list.value.splice(findIndex, 1);

                loadCustomReferralCodeCount();
              } catch (exception) {
                showCatchDialog(exception, 422);
              } finally {
                confirmUpdate.value.close();
              }
            },
          },
        ],
      });
    };

    onMounted(() => {
      loadCustomReferralCodeCount();
    });

    watch(
      () => [searchByName.value, searchByLocation.value, searchByDate.value],
      debounce(() => {
        getNewPage(1);
      }, 750)
    );

    return {
      list,
      total,
      isLoading,
      isEditLoading,
      perPage,
      getNewPage,
      confirmUpdate,
      currentPage,
      datePickerRef,
      searchByName,
      searchByLocation,
      searchByDate,
      clearDateSelection,

      renderComponent,

      isLoadingCount,
      customReferralCount,

      profile,

      userAccount,
      memberProfile,
      cancel,

      removeIsEditMode,
      editCode,
      editData,

      saveEditCode,
      deleteCode,
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>

<style scoped>
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}

.dropdown-menu-lg {
  min-width: auto;
}

.table td,
.table th {
  border-top: 0 !important;
}

.table td {
  vertical-align: middle;
}

.custom-control-label {
  font-weight: 500 !important;
}

.send {
  color: rgb(4, 98, 193);
}

.selected-message-type {
  background-color: #333;
  color: #cacaca !important;
}
</style>
