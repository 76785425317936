<template>
  <div class="card">
    <div v-if="isMounted" class="card-body row align-items-center pb-5">
      <div class="col">
        <custom-member-form
          v-if="showMemberForm"
          @selected="memberSelected"
          @submit="submitHandler"
        >
          <template #submit="{ submit, isSubmitting, reset }">
            <div class="form-group d-flex justify-content-end">
              <ce-button
                v-if="!isSubmitting"
                type="button"
                class="ce-buttons-button btn btn-outline-black fw-bold py-2 px-5 my-auto me-2"
                @click.stop="reset"
              >
                Cancel
              </ce-button>
              <ce-button
                type="button"
                class="btn-gradient-gold fw-bold py-2 px-5"
                :is-loading="isSubmitting"
                :disabled="isSubmitting"
                @click="submit"
              >
                Add Code
              </ce-button>
            </div>
          </template>
        </custom-member-form>
      </div>
      <div
        v-if="showMemberForm && showMerchantForm"
        class="col-2 fs-2 ff-merriweather text-black fw-bold text-center"
      >
        OR
      </div>
      <div class="col">
        <custom-merchant-form
          v-if="showMerchantForm"
          @selected="merchatSelected"
          @submit="submitHandler"
        >
          <template #submit="{ submit, isSubmitting, reset }">
            <div class="form-group d-flex justify-content-end">
              <ce-button
                v-if="!isSubmitting"
                type="button"
                class="ce-buttons-button btn btn-outline-black fw-bold py-2 px-5 my-auto me-2"
                @click.stop="reset"
              >
                Cancel
              </ce-button>
              <ce-button
                type="button"
                class="btn-gradient-gold fw-bold py-2 px-5"
                :is-loading="isSubmitting"
                :disabled="isSubmitting"
                @click="submit"
              >
                Add Code
              </ce-button>
            </div>
          </template>
        </custom-merchant-form>
      </div>
    </div>
  </div>
  <dialog-modal ref="confirmUpdate" />
</template>

<script>
import { ref, defineAsyncComponent } from "vue";
import { useRouter } from "vue-router";
import { useRequestCatchDialog } from "@/composable/catch-dialog.js";
import http from "@/http.js";

export default {
  name: "App",
  components: {
    CustomMemberForm: defineAsyncComponent(() =>
      import("@/components/Referrals/CustomMemberForm.vue")
    ),
    CustomMerchantForm: defineAsyncComponent(() =>
      import("@/components/Referrals/CustomMerchantForm.vue")
    ),
    DialogModal: defineAsyncComponent(() =>
      import("@/components/Modal/DialogModal.vue")
    ),
  },
  props: {
    //
  },
  emits: ["isLoading"],
  setup(props, { emit }) {
    const router = useRouter();
    const { catchDialog: showCatchDialog } = useRequestCatchDialog();

    const isMounted = ref(true);
    const confirmUpdate = ref();
    const isValidForm = ref(false);

    const showMemberForm = ref(true);
    const showMerchantForm = ref(true);

    const isSubmitting = ref(false);

    const memberSelected = (isSelected) => {
      if (isSelected) {
        showMemberForm.value = true;
        showMerchantForm.value = false;
        return true;
      }
      showMemberForm.value = true;
      showMerchantForm.value = true;
      return true;
    };
    const merchatSelected = (isSelected) => {
      if (isSelected) {
        showMemberForm.value = false;
        showMerchantForm.value = true;
        return true;
      }
      showMemberForm.value = true;
      showMerchantForm.value = true;
      return true;
    };

    const submitHandler = (formData) => {
      isSubmitting.value = true;
      confirmUpdate.value.prompt("", {
        title: "Are you sure you want to create this custom code?",
        buttons: [
          {
            label: "No",
            class: "btn-outline-black",
            handler: () => {
              isSubmitting.value = false;
              confirmUpdate.value.close();
            },
          },
          {
            label: "Yes",
            class: "btn-gradient-gold",
            handler: async () => {
              try {
                await http.post(`admin-panel/custom-referral-codes`, {
                  membership_type: formData.membershipType,
                  user_id: formData.userId,
                  referral_code: formData.referralCode,
                  points: formData.points,
                });
                router.replace({ name: `ManageCustomReferralCodes` });
              } catch (exception) {
                showCatchDialog(exception, 422);
                isSubmitting.value = false;
              } finally {
                isSubmitting.value = false;
                confirmUpdate.value.close();
              }
            },
          },
        ],
      });
    };

    return {
      isMounted,
      confirmUpdate,
      isValidForm,
      memberSelected,
      merchatSelected,
      showMerchantForm,
      showMemberForm,
      submitHandler,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/customizations";

.section-fields {
  margin-bottom: 3rem;

  &::after {
    content: "";
  }
}

.section-title {
  font-size: 1.75rem;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 1em;
}

.form-field {
  margin-bottom: 1rem;
}

.event-description {
  :deep(textarea) {
    height: 175px;
  }
}

.found-past-events-keyword {
  :deep(.form-control) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.video-field {
  background: $color-light-gray;
  width: 100%;
  position: relative;

  :deep(.ce-buttons-button) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    color: $color-white;
  }
}

.submit-button {
  padding: 0.9em 6.3em;
}

.section-divider {
  margin: 3rem -4rem;
  background-color: #e7e7e7;
}

.found-past-events-list,
.found-people-dropdown {
  background: $color-white;
  box-shadow: $box-shadow-sm;
  border-radius: $border-radius-lg;
}

.found-past-events-list {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
