import axios from 'axios'

const setupHeaderConfig = (config) => {
    // -------------------------------------------------------------
    // Note: This will be uncommented once I finish the auth state / store
    // -------------------------------------------------------------
    // const token
    const token = localStorage.getItem('token')
        // !config.headers ? config.headers = {} : null\
    if (config.headers === undefined) {
        config.headers.Authorization = 'Bearer ' + token
    } else {
        config.headers.Authorization = 'Bearer ' + token
    }

    return config
}

export default ({
    state: {
        dealList: [],
        dealStatistics: [{
            icon: 'user',
            labels: 'Deals',
            data: 0,
            link: '/manage-deals',
            heading: [],
            // menu1: [{
            //         label: 'All',
            //         link: '/manage-users?type=reset-password'
            //     },
            //     {
            //         label: 'Featured',
            //         link: '/manage-users?type=update-profile'
            //     }
            // ],
            // menu2: []
        }],
    },
    mutations: {
        loadStatistics(state, payload) {
            state.dealStatistics[0].heading = payload.statistics
            state.dealStatistics[0].data = payload.all
        },
        loadLists(state, payload) {
            state.deaList = payload
        },
    },
    getters: {},
    actions: {
        async loadUDealStatistics({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/deals/statistics', newConfig).then((response) => {
                commit('loadStatistics', response.data.data)
            })
        },
        async loadUDealList({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/deals', newConfig).then((response) => {
                commit('loadLists', response.data.data)
                return response.data
            })
        },
        async setAsFeatured({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config)
            return await axios.post(`admin-panel/deals/${params.id}/set-featured`, params , newConfig).then((response) => {
                return response.data
            })
        },
        async setDealShowOnResult({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config)
            return await axios.post(`admin-panel/deals/${params.id}/set-show-on-result`, params , newConfig).then((response) => {
                return response.data
            })
        },
    },
});
