<template>
  <div>
    <form @submit.prevent="search()">
      <div class="row g-3">
        <div class="col-12">
          <ce-text
            v-model.trim="searchForm.keyword"
            type="text"
            class="found-past-events-keyword"
            placeholder="Enter keyword"
          />
        </div>
        <div class="col-12">
          <ce-text
            v-model.trim="searchForm.eventOwner"
            type="text"
            class="found-past-events-keyword"
            placeholder="Search owner"
          />
        </div>
        <div class="col-12">
          <div class="flex-fill">
            <div class="mt-2">
              <browse-prefer-event-status-field v-model="searchForm.status" />
            </div>
          </div>
        </div>
        <div class="col-12">
          <button
            type="submit"
            class="btn btn-gradient-gold w-100 me-md-2 mt-2 mb-2"
          >
            Search
          </button>
        </div>

        <span
          type="button"
          class="btn-transparent color-gold text-center w-100"
          @click="reset()"
        >
          Reset All
        </span>
      </div>
    </form>
  </div>
</template>
<script>
import { computed, onMounted, provide, ref, watch } from "vue";
import useUrlQueryParams from "@/Use/use-url-query-params.js";
import intersection from "lodash/intersection";
import debounce from "lodash/debounce";
import LocationField from "./SingleComponent/LocationField.vue";
import BrowsePreferEventStatusField from "./Browse/BrowsePreferEventStatusField.vue";
import CeRange from "./Inputs/CeRange.vue";

export default {
  components: {
    LocationField,
    BrowsePreferEventStatusField,
    CeRange,
  },
  emits: ["search", "reset"],
  setup(props, { emit }) {
    const browseLabelRef = ref(null);
    provide("browseLabel", browseLabelRef);
    const { queryParamsObject } = useUrlQueryParams();
    const isReady = ref(false);
    const advanceSearchContainerRef = ref(null);
    const searchDebounceWait = 750;
    const isResetting = ref(false);
    const formPage = ref(window.location.pathname);

    const fallbackValues = {
      keyword: "",
      owner: "",
      status: null,
    };

    const searchFormValues = computed(() => ({
      keyword:
        props.searchParams?.keyword ||
        props.defaultValues?.keyword ||
        fallbackValues.keyword,
      eventOwner:
        props.searchParams?.owner ||
        props.defaultValues?.owner ||
        fallbackValues.eventOwner,
      status:
        props.searchParams?.status ||
        props.defaultValues?.status ||
        fallbackValues.eventStatus,
    }));
    const searchForm = ref(searchFormValues.value);

    const isAdvanceSearchOpen = ref(false);

    const isLocationFieldVisible = computed(
      () =>
        props.fields.length === 0 ||
        intersection(props.fields, ["city", "state", "zipCode", "lat", "lng"])
          .length > 0
    );

    const reset = async () => {
      searchForm.value = [];
      emit("reset");
    };
    /* Methods */
    const search = () => {
      const newSearch = searchForm.value;

      const arr = [];
      Object.keys(newSearch).map((key) => {
        let newVal = null;
        if (Array.isArray(newSearch[key]) && newSearch[key]?.length > 0) {
          newSearch[key].forEach((value) => {
            if (value !== null && parseInt(value) !== 0 && value !== "") {
              newVal = snakeCaseString(key) + "[]=" + value;
              arr.push(newVal);
            }
          });
        } else {
          newVal = snakeCaseString(key) + "=" + newSearch[key];
          arr.push(newVal);
        }
      });

      emit("search", arr.toString().replace(/,/g, "&"));
    };

    const snakeCaseString = (str) => {
      return (
        str &&
        str
          .match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
          )
          .map((s) => s.toLowerCase())
          .join("_")
      );
    };

    watch(
      [
        () => searchForm.value.keyword,
        () => searchForm.value.eventOwner,
        () => searchForm.value.status,
      ],
      debounce(() => {
        search();
      }, searchDebounceWait),
      { deep: true }
    );

    watch(
      () => props.searchParams,
      () => {
        Object.assign(searchForm.value, searchFormValues.value);
      },
      {
        deep: true,
      }
    );

    /* Lifecycles */
    onMounted(() => {
      isReady.value = true;
    });

    return {
      browseLabelRef,
      isReady,
      queryParamsObject,
      advanceSearchContainerRef,
      searchDebounceWait,
      isResetting,
      formPage,
      isAdvanceSearchOpen,
      isLocationFieldVisible,
      search,
      searchForm,
      reset,
      snakeCaseString,
    };
  },
};
</script>
