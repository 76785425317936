import axios from 'axios'

const setupHeaderConfig = (config) => {
    const token = localStorage.getItem('token')
    if (config.headers === undefined) {
        config.headers.Authorization = 'Bearer ' + token
    } else {
        config.headers.Authorization = 'Bearer ' + token
    }
    return config
}

export default ({
    state: {
        referralsList: [],
        customReferralsList: [],

    },
    mutations: {
        loadReferralsList(state, payload) {
            state.referralsList = payload
        },
        loadCustomReferralsList(state, payload) {
            state.customReferralsList = payload
        },
    },
    getters: {},
    actions: {
        async validateCode({ commit }, code, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`referral/${code}`, newConfig).then((response) => {
                return response.data;
            })
        },
        async getReferralHistory({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/referrals', newConfig).then((response) => {
                return response.data
            })
        },
        async getCustomReferralList({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/custom-referral-codes', newConfig).then((response) => {
                return response.data
            })
        },
        async getCustomReferralCodeCount({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/count-custom-referral-codes', newConfig).then((response) => {
                return response.data
            })
        },
    },
});
