import ManageDeal from '@/Pages/Deal/ManageDeal.vue'

export default [
    {
        path: '/manage-deals',
        name: 'ManageDeal',
        props: { moduleName: `deal` },
        component: ManageDeal
    }
]
