<template>
  <div class="card p-0 mt-5">
    <div class="table-responsive">
      <ce-form
        ref="customReferralForm"
        v-slot="{ errors, touch, submit }"
        v-model:is-valid="hasValidReferralCodeForm"
        :data="referralCodeCreationForm"
        :rules="rules"
        @submit="submitHandler"
      >
        <table class="table table-items mt-n2">
          <thead>
            <tr class="fs-6 fw-bold text-dark-gray-2">
              <th scope="col" class="col-3">CODE NAME</th>
              <th scope="col" class="col-3">CITY & STATE</th>
              <th scope="col" class="col-2">DATE</th>
              <th scope="col" class="col-3">MEMBER NAME</th>
              <th scope="col" class="text-center col-1">TAKE ACTION</th>
            </tr>
          </thead>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="6" class="w-100 p-5 text-center">
                <div class="spinner-border text-gray" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="list.length === 0">
            <tr>
              <td colspan="6" class="w-100 p-5 text-center text-bold">
                Data not available
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <template v-for="row in list" :key="row.id">
              <tr v-if="row?.user" class="fs-6">
                <td>
                  <template v-if="row?.isEditMode">
                    <div class="row">
                      <div class="col-12 col-md-7">
                        <ce-text
                          id="editButton"
                          v-model.lazy="referralCodeCreationForm.code_name"
                          :placeholder="`Edit Referral Code`"
                          :input-class="'text-uppercase border-light-gray-border bg-white'"
                          @blur="touch('code_name')"
                          @keydown.space="(event) => event.preventDefault()"
                          @input="removeSpace($event, $event.target.value)"
                        >
                        </ce-text>
                        <template v-if="errors.code_name?.includes('required')">
                          <div class="form-text text-red fw-semibold">
                            Referral Code Name is required.
                          </div>
                        </template>
                        <template
                          v-else-if="errors.code_name?.includes('nameExist')"
                        >
                          <div class="form-text text-red fw-semibold">
                            This code name already exists. Please try a
                            different code name.
                          </div>
                        </template>
                      </div>
                      <div
                        class="col-12 col-md-5 d-flex justify-content-around align-items-center"
                      >
                        <ce-button
                          :is-loading="isEditSubmit"
                          class="btn-sm btn-outline-black"
                          type="button"
                          @click="cancelEdit"
                        >
                          Cancel
                        </ce-button>
                        <ce-button
                          :is-loading="isEditSubmit"
                          :disabled="
                            referralCodeCreationForm.previous_code_name ===
                            referralCodeCreationForm.code_name
                          "
                          class="btn-sm btn-gradient-gold"
                          type="button"
                          @click.stop="submit"
                        >
                          Save
                        </ce-button>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    {{ row?.referral_code }}
                  </template>
                </td>

                <td>
                  <template
                    v-if="
                      row?.user?.is_merchant &&
                      row?.user?.business_profile?.location
                    "
                  >
                    {{
                      [
                        row?.user?.business_profile?.location?.city,
                        row?.user?.business_profile?.location?.state,
                      ].join(", ")
                    }}
                  </template>
                  <template v-else-if="row?.user?.profile">
                    {{
                      [
                        row?.user?.profile?.city,
                        row?.user?.profile?.state,
                      ].join(", ")
                    }}
                  </template>
                </td>
                <td>
                  {{ moment(row?.created_at).format("MMM DD, YYYY") }}
                </td>
                <td>
                  <span class="link-name" data="modal" @click="profile(row)">
                    <ce-image
                      class="rounded mr-3 tbl-img"
                      :src="row?.user?.primary_photo?.sizes?.thumbnail"
                      :placeholder="`/images/defaults/gender-other.png`"
                      :alt="`Referrals List - ${row?.user?.first_name} ${row?.user?.last_name}`"
                    />
                    <span v-if="row?.user?.user_name" class="text-blue">
                      {{
                        [row?.user?.first_name, row?.user?.last_name].join(" ")
                      }}
                    </span>
                    <span v-else class="text-blue">{{ row?.user?.email }}</span>
                  </span>
                </td>
                <td class="text-center">
                  <template v-if="!row?.isEditMode">
                    <div class="dropdown me-1">
                      <button
                        id="takeActionDropdown"
                        class="btn btn-link text-decoration-none btn-md text-blue fw-semibold"
                        data-toggle="dropdown"
                      >
                        Take Action
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="takeActionDropdown"
                      >
                        <li
                          class="dropdown-item w-100 fw-semibold"
                          @click="editCode(row)"
                        >
                          Edit Code
                        </li>
                        <li
                          class="dropdown-item w-100 fw-semibold"
                          @click="deleteCode(row)"
                        >
                          Delete Code
                        </li>
                      </ul>
                    </div>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </ce-form>
    </div>
    <div
      v-if="hasPagination"
      class="d-flex justify-content-center align-content-center p-3"
    >
      <ce-pagination
        v-model:current-page="currentPage"
        :per-page="perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import { ref, watch, inject, computed } from "vue";
import CePagination from "@/components/Paginations/CePagination.vue";

import { required, helpers } from "@vuelidate/validators";
import { useForm } from "@inertiajs/inertia-vue3";
import { useStore } from "vuex";
import { useTextModification } from "@/Use/use-text-modification.js";

const { withAsync } = helpers;

export default {
  name: "ReferralsTable",
  components: {
    CePagination,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasPagination: {
      type: Boolean,
      required: false,
      default: false,
    },
    list: {
      type: Array,
      required: false,
      default: () => [],
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
    perPage: {
      type: Number,
      required: false,
      default: 0,
    },
    editData: {
      type: Object,
      required: false,
      default: () => {},
    },
    isEditSubmit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["changePage", "showProfile", "cancelEdit", "edit", "save", "delete"],
  setup(props, { emit }) {
    const moment = inject("moment");

    const { removeSpace } = useTextModification();

    const totalPage = ref(3);
    const currentPage = ref(1);

    const tableList = ref(props.list);

    const referralCodeFormValues = computed(() => ({
      id: null,
      previous_code_name: null,
      code_name: null,
    }));

    const referralCodeCreationForm = useForm(referralCodeFormValues.value);

    const validateCodeNameExists = async (codeName) => {
      try {
        await store.dispatch(`validateCode`, codeName);
        return true;
      } catch (exception) {
        return false;
      }
    };

    const rules = {
      code_name: {
        required,
        nameExist: withAsync(async (value) => {
          const isExist = await validateCodeNameExists(value);
          return !isExist;
        }),
      },
    };

    const submitHandler = () => {
      if (
        referralCodeCreationForm.previous_code_name !==
        referralCodeCreationForm.code_name
      ) {
        emit("save", {
          id: referralCodeCreationForm.id,
          referralCode: referralCodeCreationForm.code_name,
        });
      }
    };

    const profile = (row) => {
      emit("showProfile", row?.user?.user_name);
    };

    const cancelEdit = () => {
      emit("cancelEdit");
    };

    const editCode = (row) => {
      emit("edit", row);
    };

    const deleteCode = (row) => {
      emit("delete", row?.id);
    };

    watch(
      () => currentPage.value,
      (newPage) => emit("changePage", newPage)
    );

    watch(
      () => props.editData,
      (value) => {
        console.log("editData", value);
        referralCodeCreationForm.id = value?.id;
        referralCodeCreationForm.previous_code_name = value?.code_name;
        referralCodeCreationForm.code_name = value?.code_name;
      },
      {
        deep: true,
      }
    );

    return {
      moment,
      tableList,
      totalPage,
      currentPage,
      profile,
      editCode,
      cancelEdit,

      rules,
      submitHandler,
      removeSpace,
      referralCodeCreationForm,
      deleteCode,
    };
  },
};
</script>

<style scoped>
.color-gray {
  color: #727e8b;
}

.form-control:focus {
  box-shadow: none;
}

.btn:focus {
  box-shadow: none;
}

.color-gold {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}

.top-70 {
  top: 0% !important;
}

.start-58 {
  left: 58% !important;
}

.card {
  border: 0;
}

.widget-user-header {
  padding: 0;
  height: auto !important;
}

.widget-user .widget-user-header {
  padding: 0px;
}

.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}

.dropdown-menu-lg {
  min-width: auto;
}

.link-name {
  color: rgb(85, 85, 194);
}

.link-name:hover {
  cursor: pointer;
}

.row-name {
  width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.view-profile {
  color: #b18d47;
}

.cancel-profile {
  color: #878787;
}

.link-profile:hover {
  cursor: pointer;
}

.table-responsive {
  margin-top: -35px;
}

.tbl-img {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}

.profile-img {
  object-fit: contain;
  border-radius: 0.25em 0.25em 0em 0px;
  width: 100%;
}

.table-items th {
  padding-bottom: 2px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.table-items tbody,
tr,
td {
  white-space: nowrap !important;
}

.fa-circle-plus,
.fa-pen-to-square,
.fa-magnifying-glass-plus:hover {
  cursor: pointer;
}
</style>
