<template>
  <div class="input-group ce-inputs-text" :class="[sizeClass]">
    <template v-if="$slots.prepend">
      <slot name="prepend"></slot>
    </template>
    <template v-else-if="prepend">
      <span class="input-group-text">{{ prepend }}</span>
    </template>
    <input
      ref="inputRef"
      v-mask="computedMask"
      :value="localValue"
      :type="computedType"
      :max="max"
      :min="min"
      :placeholder="placeholder"
      class="form-control"
      :class="inputClass"
      :disabled="disabled"
      :readonly="readonly"
      :maxlength="maxlength"
      :size="length"
      @input="updateLocalValue($event)"
      @blur="handleBlur()"
      @keypress.space="
        (e) => (maxWords && wordsCount >= maxWords ? e.preventDefault() : null)
      "
    />
    <template v-if="type === 'password'">
      <span
        class="input-group-text password-toggle"
        :class="[readablePassword ? 'icon-eye-hidden' : 'icon-eye']"
        @click="readablePassword = !readablePassword"
      ></span>
    </template>
    <template v-if="localIsLoading !== null">
      <span
        class="input-group-text loading-indicator"
        :class="[{ 'is-loading': localIsLoading }, inputLoadingClass]"
      ></span>
    </template>
    <template v-if="$slots.append">
      <slot name="append"></slot>
    </template>
    <template v-else-if="append">
      <span class="input-group-text">{{ append }}</span>
    </template>
    <template v-if="maxWords">
      <div class="w-100 text-end">{{ wordsCount }} / {{ maxWords }} Words</div>
    </template>
  </div>
</template>
<script>
/* global google */
import { computed, watch, ref, nextTick, inject, onMounted } from "vue";
import autocomplete from "autocompleter";
import flatpickr from "flatpickr";

const locationObjectToString = (locationObject) => {
  return `${[
    locationObject?.venue,
    locationObject?.street,
    locationObject?.city,
    locationObject?.state,
  ]
    .filter(Boolean)
    .reduce((accumulator, currentValue) => {
      if (
        accumulator &&
        currentValue &&
        accumulator.toLowerCase().indexOf(currentValue.toLowerCase()) !== -1
      ) {
        return accumulator;
      }

      return accumulator
        ? `${accumulator}, ${currentValue}`
        : `${currentValue}`;
    }, locationObject.venue || "")} ${locationObject.zipCode || ""}`;
};

export default {
  props: {
    modelValue: {
      type: [String, Object, Array],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: [String, Array],
      default: "",
    },
    inputLoadingClass: {
      type: [String, Array],
      default: "",
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    suggestionValue: {
      type: String,
      default: null,
    },
    suggestionLabel: {
      type: String,
      default: null,
    },
    prepend: {
      type: String,
      default: null,
    },
    append: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: (props) => (props.type === "location" ? false : null),
    },
    locationType: {
      type: String,
      default: "city", // city | venue
    },
    autocompleteClassName: {
      type: String,
      required: false,
      default: "",
    },
    maxDate: {
      type: Date,
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
    mask: {
      type: [String, Object],
      default: null,
      required: false,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    allowInputDate: {
      type: Boolean,
      default: false,
    },
    dateFormat: {
      type: String,
      default: null,
      required: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: undefined,
    },
    maxWords: {
      type: Number,
      default: null,
    },
  },
  emits: ["update:modelValue", "update:isLoading", "selected", "blur"],
  setup(props, { emit }) {
    const moment = inject("moment");
    const localValue = ref(null);
    const inputRef = ref(null);
    const inputPluginInstance = ref(null);
    const isGoogleMapLoaded = ref(null);
    const localIsLoading = ref(props.isLoading);
    const readablePassword = ref(false);
    const hasModifiedLocation = ref(false);

    const computedType = computed(() => {
      switch (props.type) {
        case "password":
          return readablePassword.value ? "text" : "password";

        case "numeric":
          return "tel";

        case "birthdate":
          return "date";

        case "calendar":
          return "date";

        default:
          return props.type;
      }
    });

    const sizeClass = computed(() => {
      switch (props.size) {
        case "sm":
          return "input-group-sm";

        case "lg":
          return "input-group-lg";

        default:
          return "";
      }
    });

    // maximum date
    const max = computed(() => {
      switch (props.type) {
        case "birthdate":
          return inject("moment")().subtract(18, "years").format("YYYY-MM-DD");

        default:
          return props.maxDate
            ? inject("moment")(props.maxDate).format("YYYY-MM-DD")
            : null;
      }
    });

    // minimum date
    const min = computed(() => {
      switch (props.type) {
        case "birthdate":
          return inject("moment")().subtract(115, "years").format("YYYY-MM-DD");

        default:
          return props.minDate
            ? inject("moment")(props.minDate).format("YYYY-MM-DD")
            : null;
      }
    });

    const computedMask = computed(() => {
      switch (props.type) {
        case "numeric":
          return "0000000";

        case "telephone":
          return "000-000-0000";

        case "currency":
          return "CUR";
        case "zip-code":
          return "00000-[0000]";

        default:
          return props.mask || null;
      }
    });

    const wordsCount = computed(
      () => localValue.value?.match(/\b\S+\b/gu)?.length || 0
    );

    /* Methods */
    const updateLocalValue = async (e) => {
      await nextTick();
      localValue.value = e.target.value;
      if (props.type === "location") {
        hasModifiedLocation.value = true;
      }
      return true;
    };

    const loadGoogleMapsApi = async () => {
      isGoogleMapLoaded.value = false;
      const scriptId = "google-maps-api";
      if (document.querySelector(`#${scriptId}`)) {
        return true;
      }
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places`;
      document.head.appendChild(script);

      await new Promise((resolve) => {
        script.onload = () => {
          resolve();
        };
      });
      isGoogleMapLoaded.value = true;
      return true;
    };

    const handleBlur = () => {
      if (hasModifiedLocation.value === true) {
        localValue.value = locationObjectToString(props.modelValue);
      }

      if (props.type === "birthdate") {
        emit("blur", max, min);
      } else {
        emit("blur");
      }
    };

    const handleDateToday = (dateToday) => {
      if (
        (props.type === "date" || props.type === "calendar") &&
        inputPluginInstance.value
      ) {
        inputPluginInstance.value.setDate(
          props.range
            ? [
                flatpickr.parseDate(dateToday, "Y-m-d"),
                flatpickr.parseDate(dateToday, "Y-m-d"),
              ]
            : flatpickr.parseDate(dateToday, "Y-m-d"),
          true,
          "F d, Y"
        );
      }

      return true;
    };

    const imposeMaxWords = (texts) => {
      if (!props.maxWords || wordsCount.value <= props.maxWords) {
        return texts;
      }

      const words = localValue.value
        ?.match(/\b\S+\b/gu)
        .slice(0, props.maxWords);
      const spaces = localValue.value?.match(/\s+/gu);

      const final = words.reduceRight(
        (prev, curr) => curr + (spaces?.pop() || "") + prev,
        ""
      );

      return final;
    };

    const processTexts = (texts) => {
      let finalTexts = texts || "";
      if (props.maxWords) {
        finalTexts = imposeMaxWords(finalTexts);
      }

      return finalTexts;
    };

    /* Watchers */
    watch(localValue, (newLocalValue) => {
      if (props.type === "location" && newLocalValue !== "") {
        return false;
      }

      if ((props.type === "date" || props.type === "calendar") && props.range) {
        return false;
      }

      emit("update:modelValue", processTexts(newLocalValue));

      return true;
    });

    watch(
      () => props.modelValue,
      (newModelValue) => {
        localValue.value =
          props.type === "location"
            ? locationObjectToString(newModelValue)
            : newModelValue;

        if (props.type === "location") {
          localValue.value = locationObjectToString(newModelValue);
        } else if (props.suggestions.length > 0) {
          const selectedSuggestion = props.suggestions.find((suggestion) => {
            if (props.suggestionValue) {
              return suggestion[props.suggestionValue] === newModelValue;
            }
            return suggestion === newModelValue;
          });
          localValue.value =
            selectedSuggestion?.[props.suggestionLabel] || newModelValue;
        } else {
          localValue.value = processTexts(newModelValue);
        }

        if (
          (props.type === "calendar" || props.type === "time") &&
          inputPluginInstance.value
        ) {
          inputPluginInstance.value.setDate(newModelValue);
        }
      },
      {
        immediate: true,
      }
    );

    watch(localIsLoading, (newLocalIsLoading) => {
      emit("update:isLoading", newLocalIsLoading);
    });

    watch(
      () => props.isLoading,
      (newIsLoading) => {
        localIsLoading.value = newIsLoading;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.minDate,
      (newMinDate) => {
        if (
          !props.disabled &&
          (props.type === "date" ||
            props.type === "calendar" ||
            props.type === "time")
        ) {
          inputPluginInstance.value.set("minDate", newMinDate || undefined);
        }
      }
    );

    watch(
      () => props.maxDate,
      (newMaxDate) => {
        if (
          !props.disabled &&
          (props.type === "date" ||
            props.type === "calendar" ||
            props.type === "time")
        ) {
          inputPluginInstance.value.set("maxDate", newMaxDate || undefined);
        }
      }
    );

    watch(
      () => props.disabled,
      (isDisabled) => {
        if (
          inputPluginInstance.value?.altInput &&
          (props.type === "date" ||
            props.type === "calendar" ||
            props.type === "time")
        ) {
          inputPluginInstance.value.altInput.disabled = isDisabled;
        }
      }
    );

    /* Lifecycles */
    onMounted(() => {
      if (props.type === "location") {
        loadGoogleMapsApi();
      }

      if (props.type === "location" || props.suggestions.length > 0) {
        autocomplete({
          input: inputRef.value,
          className: `ce-inputs-text-autocomplete ${props.autocompleteClassName}`,
          preventSubmit: props.type === "location",
          debounceWaitMs: props.type === "location" ? 250 : 0,
          async fetch(text, update) {
            const lowerText = text.toLowerCase();
            let suggestions = [];

            if (props.type === "location") {
              const service = new google.maps.places.AutocompleteService();
              localIsLoading.value = true;
              const googleMapSuggestions = await new Promise((resolve) => {
                const types = [];
                if (props.locationType === "city") {
                  types.push("(cities)");
                } else if (props.locationType === "venue") {
                  types.push("geocode");
                  types.push("establishment");
                }

                service.getPlacePredictions(
                  {
                    input: lowerText,
                    types,
                    componentRestrictions: { country: "us" },
                  },
                  (predictions) => {
                    resolve(predictions);
                  }
                );
              });
              localIsLoading.value = false;
              suggestions = googleMapSuggestions || [];
            } else {
              suggestions = props.suggestions.filter((suggestion) => {
                let suggestionToFilter = suggestion;
                if (props.suggestionValue) {
                  suggestionToFilter = suggestion[props.suggestionLabel];
                }
                return suggestionToFilter.toLowerCase().startsWith(lowerText);
              });
            }

            update(suggestions);
          },
          render(suggestion) {
            const div = document.createElement("div");
            if (props.type === "location") {
              div.innerHTML = suggestion.description;
            } else {
              div.innerHTML = props.suggestionLabel
                ? `${suggestion[props.suggestionLabel]}`
                : suggestion[props.suggestionValue] || suggestion;
            }

            return div;
          },
          onSelect(suggestion) {
            if (props.type === "location") {
              const geocoder = new google.maps.Geocoder();
              geocoder.geocode(
                {
                  placeId: suggestion.place_id,
                },
                (results, status) => {
                  if (status === "OK") {
                    if (results[0]) {
                      const venue =
                        props.locationType === "venue"
                          ? suggestion.structured_formatting.main_text
                          : null;
                      let street = "";
                      let city = "";
                      let state = "";
                      let stateLongName = "";
                      let zipCode = "";
                      const geometryLocation = results[0].geometry.location;
                      const lat = geometryLocation.lat();
                      const lng = geometryLocation.lng();

                      results[0].address_components.forEach(
                        (addressComponent) => {
                          if (addressComponent.types.includes("route")) {
                            street = addressComponent.long_name;
                          }
                          if (addressComponent.types.includes("locality")) {
                            city = addressComponent.short_name;
                          }
                          if (
                            addressComponent.types.includes(
                              "administrative_area_level_1"
                            )
                          ) {
                            state = addressComponent.short_name;
                            stateLongName = addressComponent.long_name;
                          }
                          if (addressComponent.types.includes("postal_code")) {
                            zipCode = addressComponent.short_name;
                          }
                        }
                      );

                      const locationObject = {
                        venue,
                        street,
                        city,
                        state,
                        stateLongName,
                        zipCode,
                        lat,
                        lng,
                      };

                      emit("update:modelValue", locationObject);
                      localValue.value = locationObjectToString(locationObject);
                      hasModifiedLocation.value = false;
                      inputRef.value.blur();
                    }
                  }
                }
              );
            } else {
              emit(
                "update:modelValue",
                suggestion[props.suggestionValue] || suggestion
              );
              localValue.value =
                suggestion[props.suggestionLabel] ||
                suggestion[props.suggestionValue] ||
                suggestion;
            }

            emit("selected");
          },
        });
      }

      if (
        props.type === "date" ||
        props.type === "calendar" ||
        props.type === "birthdate"
      ) {
        const DATE_FORMAT = "YYYY-MM-DD";
        inputPluginInstance.value = flatpickr(inputRef.value, {
          enableTime: false,
          altInput: true,
          allowInput: props.allowInputDate ?? false,
          altFormat: props.dateFormat ?? "F d, Y",
          dateFormat: "Y-m-d",
          minDate:
            props.disabled || !min.value
              ? undefined
              : moment(min.value, DATE_FORMAT).toDate(),
          maxDate:
            props.disabled || !max.value
              ? undefined
              : moment(max.value, DATE_FORMAT).toDate(),
          inline: props.type === "calendar",
          mode: props.range ? "range" : "single",
          defaultDate: props.range ? localValue.value : null,
          onChange: (selectedDates, dateStr, instance) => {
            if (props.range && selectedDates.length === 2) {
              // eslint-disable-next-line no-underscore-dangle
              instance._input.blur();
              emit("update:modelValue", [
                moment(selectedDates[0]).format(DATE_FORMAT),
                moment(selectedDates[1]).format(DATE_FORMAT),
              ]);
            }
          },
        });
      }

      if (props.type === "time") {
        inputPluginInstance.value = flatpickr(inputRef.value, {
          enableTime: true,
          noCalendar: true,
          altInput: true,
          altFormat: "G:i K",
          dateFormat: "H:i:S",
        });
      }
    });

    return {
      moment,
      localValue,
      inputRef,
      computedType,
      sizeClass,
      computedMask,
      max,
      min,
      updateLocalValue,
      loadGoogleMapsApi,
      isGoogleMapLoaded,
      handleBlur,
      localIsLoading,
      handleDateToday,
      readablePassword,
      wordsCount,
      imposeMaxWords,
    };
  },
};
</script>

<style lang="scss">
/* purgecss start ignore */
.flatpickr-calendar.inline {
  &,
  .flatpickr-rContainer,
  .flatpickr-days,
  .dayContainer {
    width: 100%;
  }

  .dayContainer {
    max-width: 100%;
  }

  .dayContainer .flatpickr-day {
    max-width: unset;
  }
}

.password-toggle {
  cursor: pointer;
}

/* purgecss end ignore */
</style>

<style lang="scss" scoped>
/* purgecss start ignore */
@import "flatpickr/dist/flatpickr.min.css";

/* purgecss end ignore */
</style>
