import ReferralIndex from '@/Pages/Referral/Index.vue'
import ManageReferrals from '@/Pages/Referral/ManageReferrals.vue'
import CustomReferralCodes from '@/Pages/Referral/CustomReferralCodes.vue'
import CreateCustomCode from '@/Pages/Referral/CreateCustomCode.vue'


export default [
    {

        path: '/referrals',
        component: ReferralIndex,
        props: { moduleName: `referral` },
        children: [
            {
                path: '',
                name: 'ManageReferrals',
                component: ManageReferrals
            },
            {
                path: 'custom',
                name: 'ManageCustomReferralCodes',
                component: CustomReferralCodes
            },
            {
                path: 'create-custom',
                name: 'CreateCustomCode',
                component: CreateCustomCode
            }
        ]
    }
]
