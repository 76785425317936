export const useTextModification = () => {
    const capitalize = (text) => {
        const capitalized = [];
        text?.split(' ').forEach((word) => {
            capitalized.push(
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            );
        });
        return capitalized.join(' ');
    };

    const upperCase = (event, text) => {
        event.target.value = text?.toUpperCase();
        // return text?.toUpperCase();
    };

    const removeSpace = (event, text) => {
        event.target.value = text?.replace(/\s+/g, '');
    };

    const noSpaceToUpperCase = (event, text) => {
        event.target.value = text?.replace(/\s+/g, '').toUpperCase();
    };

    return {
        capitalize,
        upperCase,
        removeSpace,
        noSpaceToUpperCase,
    };
};

export default null;
