import http from 'axios'

export default {
  getZodiacSigns: () =>
    http.get('step-2', {
      params: {
        only: 'zodiac_sign'
      }
    }),

  getIncomeLevels: () =>
    http.get('step-2', {
      params: {
        only: 'income_level'
      }
    }),

  getEthnicities: () =>
    http.get('step-2', {
      params: {
        only: 'ethnicity'
      }
    }),

  getGenders: () =>
    http.get('step-2', {
      params: {
        only: 'gender'
      }
    }),

  getSmokingFrequencies: () =>
    http.get('step-2', {
      params: {
        only: 'smoking'
      }
    }),

  getDrinkingFrequencies: () =>
    http.get('step-2', {
      params: {
        only: 'drinking'
      }
    }),

  getChildrenOptions: () =>
    http.get('step-2', {
      params: {
        only: 'any_children'
      }
    }),

  getEducationLevels: () =>
    http.get('step-2', {
      params: {
        only: 'educational_level'
      }
    }),

  getRelationshipStatuses: () =>
    http.get('step-2', {
      params: {
        only: 'relationship_status'
      }
    }),

  getBodyTypes: () =>
    http.get('step-2', {
      params: {
        only: 'body_type'
      }
    }),

  getInterests: () =>
    http.get('step-2', {
      params: {
        only: 'interest'
      }
    }),

  getEventRoles: () => http.get('roles'),

  getTimezones: () => http.get('timezones'),

  getCategories: () => http.get('categories'),

  getTypes: () => http.get('types'),

  getChatTypes: () => http.get('chat-type-settings'),

  getGeneralStatus: () =>
    http.get('step-2', {
      params: {
        only: 'event_general_status'
      }
    }),
}
