<template>
  <ce-accordion-item :open-on-init="openOnInit">
    <template #header>
      <label class="d-block text-start small fw-semibold ls-1">STATUS</label>
    </template>
    <div class="no-preference-checkbox">
      <ce-checkbox v-model="noPreferenceValue" :value="true" type="radio">
        ALL
      </ce-checkbox>
    </div>
    <ce-checkboxes
      v-model="preferenceValue"
      class="preference-checkboxes pb-3"
      :options="
        $store.getters['option/generalStatus'].filter((v) => v.id !== 0)
      "
      :option-value-key="'id'"
      :option-label-key="'value'"
    />
  </ce-accordion-item>
</template>

<script>
import { useStore } from "vuex";
import useBrowsePreferMultipleField from "@/Use/use-browse-prefer-multiple-field.js";

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    queryType: {
      type: String,
      default: null,
      required: false,
    },
    queryFilter: {
      type: String,
      default: null,
      required: false,
    },
    openOnInit: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const store = useStore();
    store.dispatch("option/loadEventGeneralStatus");

    return {
      ...useBrowsePreferMultipleField(props, { emit }),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/browse/browse-prefer-multiple-field";
</style>
